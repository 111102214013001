import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import EmailField from '@/shared/fields/email-field';
import { ApplicationTypeField } from "@/modules/applications/fields/application-type-field";

const label = (name) => i18n.t(`APPLICATION.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`APPLICATION.PLACEHOLDERS.${name.toUpperCase()}`);

const getFields = (requireFields) => ({
    id: new IdField('id', 'Id'),
    personal_numeric_code_or_cif: new StringField('personal_numeric_code_or_cif', label('personal_numeric_code_or_cif'), { required: requireFields }),
    email: new EmailField('email', label('email'), { required: requireFields }),
    phone_number: new StringField('phone_number', label('phone_number'), { required: requireFields, phoneNumber: requireFields }),
    application_type_id: ApplicationTypeField.relationToOne(
        'application_type_id',
        label('application_register_type_name'),
        { autocompleteRequired: true },
        { placeholder: placeholder('application_type_name'), notFilterByInternal: true },
        [],
        { asyncData: true, hasPagination: true }
    ),
    details: new StringField('details', label('details'), {}),
    connected_applications: new StringField('connected_applications', label('connected_applications'))
});

const documentsFields = {
    external_document_number: new  StringField('external_document_number', label('external_document_number')),
    external_document_date: new  StringField('external_document_date', label('external_document_date')),
}

const documentsFieldsList = [documentsFields];

export class EntryApplicationModel extends GenericModel {
    static fields(requireFields = true) {
        return getFields(requireFields);
    }

    static get documentsFields() {
        return documentsFields;
    }

    static get documentsFieldsList() {
        return documentsFieldsList;
    }
}
