<template>
    <st-page :title="titleComputed" :backLink="backLink" layout="tab" pageCustomClass="application-view">
        <ApplicationRegisterForm 
            :is-new="true"
            @form-success="handleSuccess"
            @form-error="handleError"
            :record="record"
        ></ApplicationRegisterForm>
    </st-page>
</template>

<script>
import ApplicationRegisterForm from "@/modules/applications/components/ApplicationRegisterForm.vue";
import printContent from "@/shared/utils/registered-application-print";
import { mapGetters } from 'vuex';

export default {
    name: "ApplicationRegister",
    components: { ApplicationRegisterForm },
    props: {
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            record: 'applications/form/record',
        }),
        titleComputed() {
            return this.$t("APPLICATION_REGISTER.TITLE");
        },
        backLink() {
            return { name: "dashboard" };
        },
    },
    methods: {
        handleSuccess(applicationData) {
            this.showSuccessModal(applicationData);
        },
        handleError() {
            this.showErrorModal();
        },
        printApplication(applicationData) {
            printContent(applicationData);
        },
        showSuccessModal(applicationData) {
            const id = applicationData?.identification_number || applicationData?.allocated_document_series;
            const title = this.$t('APPLICATION_REGISTER.NOTIFIERS.CREATED_SUCCESS_TITLE');
            const text = `${this.$t('APPLICATION_REGISTER.NOTIFIERS.CREATED_SUCCESS_TEXT', { number: id })}`;

            this.$alert({
                type: 'success',
                title,
                width: '46rem',
                text: text,
                isTextHtml: true,
                confirmButtonText: this.$t('APPLICATION_REGISTER.NOTIFIERS.CONFIRM_BUTTON'),
                cancelButtonText: this.$t('APPLICATION_REGISTER.NOTIFIERS.CANCEL_BUTTON'),
                hasConfirmation: true,
                confirmCallback: () => {
                    this.printApplication(applicationData);
                    this.$router.push('/applications');
                },
                dismissCallback: () => {
                    window.location.reload();
                },

                callback: () => {
                    this.$router.push('/applications');
                },
            });
        },
        showErrorModal() {
            const title = this.$t('APPLICATION_REGISTER.NOTIFIERS.CREATE_ERROR_TITLE');
            const text = this.$t('APPLICATION_REGISTER.NOTIFIERS.CREATE_ERROR_TEXT');
            this.$alert({
                type: 'error',
                title,
                text: text,
                isTextHtml: true,
                showConfirmButton: false,
            });
        }
    },
};
</script>